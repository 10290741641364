
    
    .button span {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }
    

    .button svg {
        fill: #b5844c;
        transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
    }
    
    .button:hover {
        cursor: pointer;
    }
    
    .button:active {
        box-shadow: none;
    }
    
    @keyframes shakeBack {
        0% {
        transform: translate(-100%, 100%);
        }
    
        50% {
        transform: translate(20%, -20%);
        }
    
        100% {
        transform: translate(0%, 0%);
        }
    }
    