/* Common theme variables */
.theme-container {
    --red: hsl(0, 78%, 62%);
    --cyan: hsl(180, 62%, 55%);
    --orange: hsl(34, 97%, 64%);
    --blue: hsl(212, 86%, 64%);
    --varyDarkBlue: hsl(234, 12%, 34%);
    --grayishBlue: hsl(229, 6%, 66%);
    --veryLightGray: hsl(0, 0%, 98%);
    --weight1: 200;
    --weight2: 400;
    --weight3: 600;
}

/* Styles for all pages */
body {
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    background-color: var(--veryLightGray);
}

.attribution {
    font-size: 11px;
    text-align: center;
}

.attribution a {
    color: hsl(228, 45%, 44%);
}

h1:first-of-type {
    font-weight: var(--weight1);
    color: var(--varyDarkBlue);
}

h1:last-of-type {
    color: var(--varyDarkBlue);
}

@media (max-width: 400px) {
    h1 {
        font-size: 1.5rem;
    }
}

/* Styles for specific elements within the theme-container */
.theme-container .box p {
    color: var(--grayishBlue);
}

.theme-container .box {
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px var(--grayishBlue);
    padding: 30px;
    margin: 20px;
}

.theme-container img {
    float: right;
}

@media (max-width: 450px) {
    .theme-container .box {
        height: 200px;
    }
}

@media (max-width: 950px) and (min-width: 450px) {
    .theme-container .box {
        text-align: center;
        height: 180px;
    }
}

.theme-container .cyan {
    border-top: 3px solid var(--cyan);
}

.theme-container .red {
    border-top: 3px solid var(--red);
}

.theme-container .blue {
    border-top: 3px solid var(--blue);
}

.theme-container .orange {
    border-top: 3px solid var(--orange);
}

.theme-container h2 {
    color: var(--varyDarkBlue);
    font-weight: var(--weight3);
}

@media (min-width: 950px) {
    .theme-container .row1-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .theme-container .row2-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .theme-container .box-down {
        position: relative;
        top: 150px;
    }

    .theme-container .box {
        width: 20%;
    }

    .theme-container .header p {
        width: 30%;
    }
}
