@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


@layer base {
  html {
    font-family: sans-serif, "Calibri";
  }
}

.product-cards{
  background: #F8F9F9;
}

.headings{
  font-family: 'Montserrat';
}

.circle{
  border-radius: 50%;
}

.trial-btn{
  background-color:#3498db; /* Blue */
}

/* Scrollbar styling */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

/* Apply styles to scrollbar */
::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

/* Apply styles to scrollbar thumb */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

/* #A5711A  */

.card-container{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}