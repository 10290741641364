@keyframes down_to_up {
  from {
    transform: translateY(-10%);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-250px * 7)); }
}

@keyframes circularMotion {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  /* width: 100vw; */
  overflow-y: hidden;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.column-reverse {
  display: flex;
  gap: 2rem;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

@keyframes up_to_down {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Slider  */

.slider {
    background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
    height: 110px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 92%;
}

.slider::before,
.slider::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 110px;
    position: absolute;
    width: 200px;
    z-index: 2;
}

/* Styles for mobile screens */
@media only screen and (max-width: 767px) {
  .slider::before,
  .slider::after {
      width: 70px; /* Adjust the width as needed for mobile */
  }
}

/* Styles for medium-sized screens */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider::before,
  .slider::after {
      width: 150px; 
  }
}

.slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.slider::before {
    left: 0;
    top: 0;
}

.slide-track {
    animation: scroll 20s linear  infinite;
    display: flex;
    width: calc(250px * 14);
}

.slide {
    height: 100px;
    width: 250px;
}
